import IconNoProfileImage from "../assets/icons/no-profile-picture.svg?string";
import IconTableUser from "../assets/icons/table-user.svg";
import IconTableEmail from "../assets/icons/table-email.svg";
//
import { Table } from "../components/table";
import type { ApiResponse } from "../apis/_model";
import { EmptyTable } from "../components/empty-table";
import type { _SolidState } from ":shared/utils/state-manager";
import { updateRecievedRequest } from "../apis/update-recieved-request";
import { global } from ":global";
export function TableRecievedRequests(props: { teams: _SolidState.State<ApiResponse.GetTeams> }) {
  return (
    <Table
      // class="debug$"
      fallback={<EmptyTable msg="You do not have any requests in your inbox " />}
      classes={{
        desktop: {
          columnContainer: "bg#bg-default  font-semibold text-0.77rem rounded-t-6 ",
          singleColumn: "h-3.5rem ",
          singleRowContainer:
            " overflow-y-hidden h-3rem text-0.75rem min-h-5rem border-b-2 border-solid border-gray-100",
          singleRow: "",
        },
        phone: {
          cardsContainer: "space-y-5 !h-full",
          singleCardContainer:
            "shadow-md w-full !min-h-10rem py-2 border-2 border#bg-default border-solid rounded-xl !justify-between",
          allCardsRows: "!w-90% !justify-betwen py-1.5",
          singleCardHeader: "!text-0.5rem text-gray w-full",
          singleCardValue: "!w-fit !text-0.45rem",
        },
      }}
      // rows={null}
      rows={props.teams.value.received_requests}
      columns={{
        User: {
          desktop: {
            // width: "40%",
            icon: <IconTableUser class="w-24px" />,
            class: "pl-8 flex-[0.7]",
          },
          phone: {
            cardHeaderClass: "!hidden !max-w-0 ",
            class: "border-b-2 border-solid border-gray-100",
          },
          key: (r, helper) => {
            return (
              <div class="flex flex-row space-x-2">
                <img
                  class="w-80px h-80px <md:(w-50px h-50px) rounded-full object-cover"
                  src={r.image ?? IconNoProfileImage}
                  alt={"profile_image"}
                />
                <p class="flex flex-col ">
                  <span class="font-bold text-0.8rem <md:text-0.6rem">{`${r.name ?? "Unknown Name"}`}</span>
                  <span>{`${r.reference ?? "#000000"}`}</span>
                </p>
              </div>
            );
          },
        },
        "Email Address": {
          desktop: {
            icon: <IconTableEmail class="w-24px" />,
            // width: "20%",
            class: "flex-[0.5]",
          },
          phone: {},
          key: "email",
        },
        Type: {
          desktop: {
            // icon: <IconTableEmail class="w-24px" />,
            // width: "20%",
            class: "flex-[0.5] capitalize ",
          },
          phone: {
            cardValueClass: "w-full whitespace-nowrap capitalize",
          },
          key: "type",
        },
        Action: {
          desktop: {
            width: "15%",
            headerClass: "-ml-1",
            // class: "flex-[0.5]",
          },
          key: (r) => {
            return (
              <div class="flex flex-row space-x-2">
                <span
                  class="text-green underline cursor-pointer"
                  onclick={() => {
                    global.store.actions.popop.pushConfirmDialog({
                      type: "info",
                      message: `Are you sure you want to accept ${r.name} request/invitation?!`,
                      events: {
                        async onConfirmed() {
                          return updateRecievedRequest({
                            item: r,
                            status: "accepted",
                          }).then((data) => {
                            global.store.actions.alert.pushToast({
                              type: "success",
                              message: `invitation accpeted!`,
                            });
                            props.teams.set((s) => {
                              const received_requests = s.received_requests.filter((x) => x.id !== r.id);
                              const team_members = data.team_members;
                              // team_members.push({ ...r, role: "member" });
                              return { ...s, received_requests, team_members };
                            });
                          });
                        },
                      },
                    });
                  }}
                >
                  Accept
                </span>
                <span
                  class="text-red underline cursor-pointer"
                  onclick={() => {
                    global.store.actions.popop.pushConfirmDialog({
                      type: "warn",
                      message: `Are you sure you want to reject ${r.name} request/invitation?!`,
                      events: {
                        async onConfirmed() {
                          return updateRecievedRequest({
                            item: r,
                            status: "rejected",
                          }).then(() => {
                            global.store.actions.alert.pushToast({
                              type: "success",
                              message: `invitation rejected!`,
                            });
                            props.teams.set((s) => {
                              const received_requests = s.received_requests.filter((x) => x.id !== r.id);
                              return { ...s, received_requests };
                            });
                          });
                        },
                      },
                    });
                  }}
                >
                  Remove
                </span>
              </div>
            );
          },
        },
      }}
    />
  );
}
